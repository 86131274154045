<template>
  <div style="width: 100%; height: 100%; padding: 20px; box-sizing: border-box">
    <div class="cert-container pdf-screen" ref="print" id="print" v-if="project_code == '22-23EAE'">
      <img v-if="hasAward" src="../../assets/image/cert_bg.jpg" crossorigin="anonymous" class="cert-bg" />
      <img v-else src="../../assets/image/cert_bg_1.jpg" crossorigin="anonymous" class="cert-bg" />
      <!--student name-->
      <div class="studentName">
        {{ studentName }}
      </div>
      <div class="entry">
        {{ entryTitle }}
      </div>
      <div class="awardName" id="awardName">
        {{ showAwardName }}
      </div>
      <div class="category" id="category">
        {{ "Category: " + categoryName }}
      </div>
      <div class="subcategory" v-if="subCategory && subCategory != ''">
        {{ "Sub-Category: " + subCategory }}
      </div>
      <div class="entryType" id="entryType">
        {{ "Entry Type: " + entryType }}
      </div>

      <div class="project_code">
        {{ showProjectName + " Extracurricular Activity Excellence (EAE)" }}
      </div>
      <div class="certDate">
        {{ showCertDate }}
      </div>
    </div>

    <div class="cert-container pdf-screen" ref="print" id="print" v-if="project_code == '23-24EAE'">
      <img src="../../assets/image/cert_bg_2.jpg" crossorigin="anonymous" class="cert-bg" />
      <!--student name-->
      <div class="studentName" style="margin-top: 265px;">
        {{ studentName }}
      </div>
      <div class="entry" style="margin-top: 345px;">
        {{ entryTitle }}
      </div>
      <div class="awardName" id="awardName" style="margin-top: 405px;">
        {{ showAwardName }}
      </div>

      <div class="project_code" style="margin-top: 475px;color: #FD9B2E;">
        {{ showProjectName + " Extracurricular Activity Excellence Awards" }}
      </div>
      <div class="entryType">Type: {{ entryType }} | {{ "Category: " + categoryName }} {{ entryType == 'Team' ? '| Team
        Type: ' + team_type : ''
        }}
      </div>
      <div class="tags">
        {{ tags }}
      </div>
      <div class="certDate">
        {{ showCertDate }}
      </div>
    </div>
    <el-button type="primary" size="small" style="margin-bottom: 20px; position: absolute" @click="downloadPdf">
      Download
    </el-button>
  </div>
</template>

<script>
import "../../assets/common/font.css";
import { getReviewEntryV1 } from "../../api/eae";

export default {
  name: "index",
  data() {
    return {
      entry_id: this.$route.query.id,
      studentName: "",
      entryTitle: "",
      categoryName: "",
      subCategory: "",
      entryType: "",
      showAwardName: "",
      showCertDate: "",
      hasAward: false,
      project_code: "",
      team_type: '',
      tags: '',
      showProjectName: ''
    };
  },
  mounted() {
    if (false) {
      this.entry_id = "65fe8f96a4640bc60d8b45ab";
    }
    //传递时间戳
    this.fetchData();
  },
  methods: {
    fetchData() {
      getReviewEntryV1(this.entry_id).then((res) => {
        let result = res.data.data;
        //这2个字段新增加的
        this.team_type = result.team_type
        let tempTags = result.tags
        this.tags = ''
        if (tempTags && tempTags.length > 0) {
          let array = tempTags.split(',')
          for (let i = 0; i < array.length; i++) {
            let tag = array[i]
            this.tags += tag
            if (i != (array.length - 1)) {
              this.tags += ', '
            }
          }
        }

        if (result.student.student_lastName_pinyin) {
          this.studentName =
            result.student.student_givenName_pinyin +
            " " +
            result.student.student_lastName_pinyin;
        } else {
          this.studentName =
            result.student.student_lastName +
            " " +
            result.student.student_givenName;
        }
        this.entryTitle = result.title;
        this.categoryName = result.category.name;
        this.project_code = result.projectManage.project_code

        let publish_time = this.formatPublishTime(result)
        this.formatCertDate(publish_time);
        this.formatProjectName(publish_time, result.projectManage.show_name);
        if (this.project_code != '22-23EAE') {
          if (result.application_type == '个人') {
            this.entryType = 'Individual'
          } else {
            this.entryType = 'Team'
          }
        } else {
          this.formatShowEntryType(result.entry_type);
        }
        this.$nextTick(() => {
          this.calcScore(result);
        })
      });
    },
    formatPublishTime(result) {
            let application_type = result.application_type
            let temp_announce_date = -1
            let entry_date = result.projectManage.entry_date
            entry_date.sort(function (a, b) {
                return a.ddl_date - b.ddl_date
            })
            let submission_date = result.submission_date
            //应该是离create_time最近的一个
            for (let i = 0; i < entry_date.length; i++) {
                let item = entry_date[i]
                let ddl_date = parseInt((item.ddl_date) / 1000)
                let announce_date = parseInt((item.announce_date) / 1000)
                let cert_date = -1
                if (item.cert_date && item.cert_date !='') {
                    cert_date = parseInt((item.cert_date) / 1000)
                } else {
                    cert_date = announce_date
                }
                //个人
                if (application_type == '个人' && item.app_type == 'indie' && submission_date < ddl_date) {
                    temp_announce_date = cert_date
                    break
                }
                //团队
                if (application_type == '团队' && item.app_type == 'team' && submission_date < ddl_date) {
                    temp_announce_date = cert_date
                    break
                }
            }
            return temp_announce_date
        },
    //就固定日期写死，2023年10月31日之前，老模板；2023年11月1日之后，动态调整
    formatProjectName(publish_time, showName) {
      if (publish_time <= 1698767999) {
        this.showProjectName = "22-23";
        return;
      }
      this.showProjectName = showName.replace('EAE', '')
    },

    formatCertDate(publish_time) {
      let publishTime = publish_time * 1000;
      var date = new Date(publishTime);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      this.showCertDate =
        this.formatMonthToEng(month) + " " + day + ", " + year;
    },
    formatShowEntryType(type) {
      switch (type) {
        case "team_exp":
          this.entryType = "Team Work & Experience";
          break;
        case "team_story":
          this.entryType = "Team Storyline";
          break;
        case "indie_story":
          this.entryType = "Individual Storyline";
          break;
        case "indie_exp":
          this.entryType = "Individual Work & Experience";
          break;
      }
    },
    calcScore(entry) {


      if (entry.score) {
        let entryScore = 0;
        let bonus_points = entry.score.bonus_points;
        let scoring_dimension = entry.score.scoring_dimension;
        let keys = Object.keys(scoring_dimension);
        for (let i = 0; i < keys.length; i++) {
          let score = scoring_dimension[keys[i]];
          entryScore = entryScore + parseInt(score);
        }
        let totalScore = entryScore + bonus_points;
        if (entry.projectManage.project_code == '22-23EAE') {//兼容老的
          if (totalScore >= 75) {
            this.hasAward = true;
            this.showAwardName = "Superior Award";
          } else if (totalScore >= 65) {
            this.hasAward = true;
            this.showAwardName = "Distinctive Award";
          } else if (totalScore >= 50) {
            this.hasAward = true;
            this.showAwardName = "Excellent Award";
          } else {
            this.hasAward = false;
            this.showAwardName = "";
          }
        } else {
          if (totalScore >= 90) {
            this.hasAward = true;
            let featureTitle = this.getFeatureAwardTitle(entry)
            if (featureTitle != '') {
              this.showAwardName = "Superior " + featureTitle;
            } else {
              this.showAwardName = "Superior Award";
            }
          } else if (totalScore >= 80) {
            this.hasAward = true;
            let featureTitle = this.getFeatureAwardTitle(entry)
            if (featureTitle != '') {
              this.showAwardName = "Distinctive " + featureTitle;;
            } else {
              this.showAwardName = "Distinctive Award";
            }
          } else if (totalScore >= 65) {
            this.hasAward = true;
            let featureTitle = this.getFeatureAwardTitle(entry)
            if (featureTitle != '') {
              this.showAwardName = "Excellent " + featureTitle;
            } else {
              this.showAwardName = "Excellent Award";
            }
          } else if (totalScore >= 50) {
            this.hasAward = true;
            this.showAwardName = "Honorable Mention";
          } else {
            this.hasAward = false;
            this.showAwardName = "Extracurricular Activity Recognition";
          }
        }
      } else {
        this.hasAward = false;
        this.showAwardName = "";
      }

      if (
        entry.score &&
        entry.score.subcategory &&
        entry.score.subcategory != ""
      ) {
        this.subCategory = entry.score.subcategory;
      } else {
        this.$nextTick(() => {
          document.getElementById("entryType").style.marginTop = "520px";
          document.getElementById("category").style.marginTop = "480px";
          document.getElementById("awardName").style.marginTop = "430px";
        })
      }
      //for test
      // this.hasAward = false
      // this.showAwardName = 'Extracurricular Activity Recognition'
    },
    getFeatureAwardTitle(entry) {
      if (entry.feature_award) {
        return entry.feature_award.award_title
      }
      return ''
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
    downloadPdf() {
      this.$pdf(this.$refs.print, {
        name: new Date().getTime(),
        scale: 2,
        ignoreElements: (element) => {
          if (element.className.indexOf("el-button") !== -1) {
            return true;
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.project_code {
  position: absolute;
  text-align: center;
  color: #333333;
  width: 100%;
  margin-top: 595px;
  font-size: 18px;
  font-family: Ubuntu-Bold;
}

.studentName {
  position: absolute;
  color: #333333;
  width: 100%;
  text-align: center;
  margin-top: 270px;
  font-weight: bold;
  font-size: 37px;
  font-family: Ubuntu-BoldItalic;
}

.entry {
  position: absolute;
  color: #333333;
  width: 100%;
  text-align: center;
  margin-top: 350px;
  font-size: 18px;
  font-family: Ubuntu-Bold;
}

.awardName {
  position: absolute;
  color: #333333;
  width: 100%;
  margin-top: 420px;
  text-align: center;
  font-size: 28px;
  font-family: Ubuntu-Bold;
}

.category {
  position: absolute;
  color: #333333;
  width: 100%;
  text-align: center;
  margin-top: 464px;
  font-size: 18px;
  font-family: Ubuntu-Light;
}

.subcategory {
  position: absolute;
  text-align: center;
  color: #333333;
  width: 100%;
  margin-top: 498px;
  font-size: 18px;
  font-family: Ubuntu-Light;
}

.entryType {
  position: absolute;
  text-align: center;
  color: #333333;
  width: 100%;
  margin-top: 530px;
  font-size: 18px;
  font-family: Ubuntu-Light;
}

.tags {
  position: absolute;
  text-align: center;
  color: #333333;
  width: 100%;
  margin-top: 560px;
  font-size: 16px;
  line-height: 24px;
  font-family: Ubuntu-Light;
}

.certDate {
  position: absolute;
  left: 50px;
  bottom: 80px;
  color: #333333;
  width: 100%;
  font-size: 18px;
  font-family: Ubuntu-Bold;
}

.cert-container {
  position: absolute;
  -webkit-text-size-adjust: 100% !important;
  font-variant: normal;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 620px;
  height: 877px;
}

.cert-bg {
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
